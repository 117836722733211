import { Box, Grid, Typography, List, ListItem, Link } from '@mui/material';
import { Icon24UtilityCheckmarkLarge } from '@care/react-icons';
import { WithLazyLoadProps } from '@/utilities/withLazyLoad';
import { SxClassProps } from '@/types';

import safetyImageLarge from '@/../public/rebranding/safety-large.jpg';
import safetyImageMobile from '@/../public/rebranding/safety-mobile.jpg';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';
import { AnalyticsEventName, CtaLocation, CtaText, ScreenName } from '@/types/enums';

import { VERTICALS_NAMES } from '@/constants';

const classes: SxClassProps = {
  container: {
    width: '100%',
    margin: '0 auto',
    borderRadius: {
      xs: '0 0 64px 64px',
      sm: '0 0 120px 120px',
    },
    backgroundColor: '#FAF7F7',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    flexWrap: 'nowrap',
  },
  imageContainer: (theme) => ({
    height: {
      xs: '275px',
      sm: '354px',
      md: '557px',
    },
    width: {
      xs: '100%',
      md: '50%',
    },
    backgroundImage: {
      xs: `url(${safetyImageMobile.src})`,
      md: `url(${safetyImageLarge.src})`,
    },
    backgroundPosition: {
      xs: 'top center',
      md: '80%',
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: {
      xs: 'none',
      md: '0 0 0 120px',
    },
    overflow: 'hidden',
    paddingRight: 0,
    paddingBottom: '37px',
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 0,
      paddingRight: '189px',
    },
  }),
  quoteOverlay: (theme) => ({
    height: '100%',
    width: '400px',
    marginLeft: '80px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }),
  quote: (theme) => ({
    display: 'inline',
    width: 'fit-content',
    marginBottom: '4px',
    padding: '0 10px',
    borderRadius: '8px',
    backgroundColor: 'rgba(33, 63, 95, 0.9)',
    color: theme.palette.care?.white,
    fontSize: '18px',
    lineHeight: '32px',
    ':last-child': {
      marginTop: '8px',
    },
  }),
  contentContainer: {
    width: {
      sm: '100%',
      md: '50%',
    },
    display: 'flex',
    justifyContent: 'center',
    paddingX: 3,
    marginTop: {
      xs: 3,
      sm: 4,
      md: 0,
    },
    marginBottom: {
      xs: 6,
      sm: 8,
      md: 0,
    },
    maxWidth: {
      xs: '500px',
      sm: 'none',
    },
    boxSizing: {
      xs: 'content-box',
      sm: 'border-box',
    },

    marginX: 'auto',
  },
  content: {
    width: 'auto',
    '> *': {
      maxWidth: {
        xs: 'none',
        sm: '560px',
        md: '500px',
      },
    },
  },
  headline: {
    marginBottom: '16px',
    width: '100%',
    fontSize: {
      xs: '28px',
      md: '36px',
    },
    lineHeight: {
      xs: '34px',
      md: '42px',
    },
  },
  headlineHighlight: (theme) => ({
    display: 'inline-block',
    width: 'fit-content',
    padding: '4px',
    borderRadius: '10px',
    backgroundColor: theme.palette.care?.blue[100],
    fontWeight: 700,
    fontSize: {
      xs: '28px',
      md: '36px',
    },
    lineHeight: {
      xs: '34px',
      md: '42px',
    },
  }),
  list: {
    marginTop: {
      xs: '24px',
      md: '32px',
    },
  },
  subHeading: {
    width: '100%',
    fontSize: {
      xs: '18px',
      sm: '21px',
    },
    fontWeight: 400,
    lineHeight: '24px',
    paddingRight: {
      md: '50px',
    },
  },
  contentText: {
    fontSize: {
      xs: '16px',
      sm: '18px',
    },
    fontWeight: 400,
    marginLeft: 2,
    lineHeight: '24px',
    '& .MuiLink-root': (theme) => ({
      color: theme.palette.text.primary,
    }),
  },
};

const quoteCopy = [
  '"Thorough and thoughtful. Care.com',
  'helped connect me with someone I can trust',
  'and I had many options to choose from."',
];

const bulletPoints = [
  'All caregivers you can hire are background-checked',
  'Supplemental Background Check reports available for purchase',
  'Dedicated safety team reviews all job posts and caregiver profiles',
];

function Safety({ hasLoaded }: WithLazyLoadProps) {
  if (!hasLoaded) {
    return null;
  }

  const handleOnClick = () => {
    AnalyticsHelper.logEvent({
      name: AnalyticsEventName.CTA_INTERACTED_VHP,
      data: {
        screen_name: ScreenName.VISITOR_HOMEPAGE,
        enrollment_initiated: true,
        cta_text: CtaText.TRUST_AND_SAFETY_CENTER,
        cta_location: CtaLocation.VHP_BODY,
        vertical: VERTICALS_NAMES.unknown,
      },
    });
  };

  return (
    <Grid container sx={classes.container}>
      <Grid container item sx={classes.imageContainer} direction="row">
        <Grid container item sx={classes.quoteOverlay} direction="column" justifyContent="flex-end">
          {quoteCopy.map((quote, index) => (
            <Typography key={`quote-${index + 1}`} variant="h2" sx={classes.quote}>
              {quote}
            </Typography>
          ))}
          <Typography variant="h3" sx={classes.quote}>
            Nancy S, Care member
          </Typography>
        </Grid>
      </Grid>
      <Box sx={classes.contentContainer}>
        <Grid
          container
          item
          sx={classes.content}
          direction="column"
          justifyContent="center"
          alignItems="center">
          <Typography variant="h2" sx={classes.headline}>
            Find{' '}
            <Typography component="span" sx={classes.headlineHighlight}>
              peace of mind
            </Typography>{' '}
            with safety resources
          </Typography>
          <Typography variant="h3" sx={classes.subHeading}>
            Our Safety Pledge means we&apos;re dedicated to you and your safety.
          </Typography>
          <List sx={classes.list}>
            {bulletPoints.map((bulletPoint, index) => (
              <ListItem key={`point-${index + 1}`} disableGutters alignItems="flex-start">
                <Icon24UtilityCheckmarkLarge width={22} height={22} />
                <Typography variant="body1" sx={classes.contentText}>
                  {bulletPoint}
                </Typography>
              </ListItem>
            ))}
            <ListItem disableGutters alignItems="flex-start">
              <Icon24UtilityCheckmarkLarge width={22} height={22} />
              <Typography variant="body1" sx={classes.contentText}>
                Learn more with dozens of articles and resources in our{' '}
                <Link
                  href="https://www.care.com/about/safety/"
                  rel="noopener noreferrer"
                  target="_blank"
                  underline="always"
                  onClick={handleOnClick}
                  sx={classes.link}>
                  trust and safety center
                </Link>
              </Typography>
            </ListItem>
          </List>
        </Grid>
      </Box>
    </Grid>
  );
}

export default Safety;
